<!-- src/components/WhatsForm.vue -->
<template>
  <div class="contact-form-container">
    <!-- Title -->
    <h2 class="form-title">Contact Us Form</h2>

    <!-- WhatsForm Iframe -->
    <iframe 
      src="https://whatsform.com/AlBkAN"  
      width="100%" 
      height="600" 
      frameBorder="0"
      class="whatsform-iframe"
    ></iframe>
  </div>
</template>

<style scoped>
.contact-form-container {
  max-width: 60%;
  margin: 0 auto;
  padding: 05px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #f9f9f9;
  border: 2px solid #e0e0e0;
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.contact-form-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.form-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  animation: fadeIn 2s ease-in-out;
}

.whatsform-iframe {
  width: 100%;
  height: 600px;
  border: none;
  border-radius: 10px;
  transition: height 0.3s ease;
}

@media (max-width: 768px) {
  .whatsform-iframe {
    height: 500px;
  }

  .form-title {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .whatsform-iframe {
    height: 400px;
  }

  .form-title {
    font-size: 1.5rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

