<template>
  <div class="book-container">
    <!-- Loop through stories and display them as book pages -->
    <div v-for="(story, index) in stories" :key="index" class="page" :class="{ active: index === currentStory }">
      <!-- Front of the page (title and content) -->
      <div class="page-front p-6 bg-gradient-to-r from-green-400 to-blue-500 shadow-lg rounded-lg">
        <h2 class="text-xl font-bold text-white">{{ story.title }}</h2>
        <p class="text-white">{{ story.content }}</p>
      </div>
      <!-- Back of the page (optional image) -->
      <div class="page-back p-6 bg-gray-100 rounded-lg shadow-lg" v-if="story.image">
        <img :src="story.image" alt="Story Image" class="w-full rounded-md" />
      </div>
    </div>

    <!-- Navigation buttons -->
    <div class="navigation mt-4">
      <button 
        class="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-800 transition" 
        @click="prevStory" 
        :disabled="currentStory === 0"
      >
        Previous
      </button>
      <button 
        class="bg-green-600 text-white px-4 py-2 ml-4 rounded-lg shadow-md hover:bg-green-800 transition" 
        @click="nextStory" 
        :disabled="currentStory === stories.length - 1"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentStory: 0,
      stories: [
        { title: "Story 1", content: "This is the first story.", image: "image1.jpg" },
        { title: "Story 2", content: "This is the second story.", image: "" },
        { title: "Story 3", content: "This is the third story.", image: "image3.jpg" },
      ],
    };
  },
  methods: {
    nextStory() {
      if (this.currentStory < this.stories.length - 1) {
        this.currentStory++;
      }
    },
    prevStory() {
      if (this.currentStory > 0) {
        this.currentStory--;
      }
    },
  },
};
</script>

<style scoped>
.book-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Ensures it covers the full viewport height */
  margin: 20px;
}

.page {
  width: 100%;
  max-width: 600px;
  height: 400px;
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
  display: none;
}

.page.active {
  display: block;
}

.page-front, .page-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 15px;
}

.page-back {
  transform: rotateY(180deg);
}

.navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border-radius: 5px;
}
</style>

