<template>
  <div class="course-container">
    <div class="course-card" v-for="course in courses" :key="course.name">
      <img :src="course.image" :alt="course.name" class="course-image" />
      <div class="course-info">
        <h3 class="course-title">{{ course.name }}</h3>
        <p class="course-details">{{ course.details }}</p>
        <p class="course-batch">{{ course.upcomingBatch }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      courses: [
        {
          name: "Azure",
          image: require("@/assets/azure.png"),
          details: "Learn about Microsoft Azure services.",
          upcomingBatch: "Next Batch: Sept 15, 2024",
        },
        {
          name: "MCSA",
          image: require("@/assets/mcsa.png"),
          details: "Microsoft Certified Solutions Associate.",
          upcomingBatch: "Next Batch: Sept 20, 2024",
        },
        {
          name: "VMware",
          image: require("@/assets/vmware.png"),
          details: "VMware Virtualization Technology.",
          upcomingBatch: "Next Batch: Oct 1, 2024",
        },
        {
          name: "IaC",
          image: require("@/assets/iac.png"),
          details: "Infrastructure as Code - Terraform, PowerShell, Git.",
          upcomingBatch: "Next Batch: Oct 10, 2024",
        },
        {
          name: "Kubernetes",
          image: require("@/assets/kubernetes.png"),
          details: "Kubernetes Certified Kubernetes Administrator (CKA).",
          upcomingBatch: "Next Batch: Oct 15, 2024",
        },
        {
          name: "Exchange Online",
          image: require("@/assets/exchange.png"),
          details: "Microsoft 365 Exchange Online.",
          upcomingBatch: "Next Batch: Oct 20, 2024",
        },
      ],
    };
  },
};
</script>

<style scoped>
.course-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

.course-card {
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  padding: 20px;
  max-width: 300px;
  flex: 1 1 calc(33.333% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.course-image {
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
}

.course-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.course-details {
  font-size: 1rem;
  margin-bottom: 10px;
}

.course-batch {
  font-size: 0.875rem;
  color: #1e40af;
  font-weight: bold;
}
</style>

