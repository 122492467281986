<template>
  <div class="page-container">
    <h1 class="page-title">Kubernetes CKA, for details
    <button class="contact-us-button" @click="navigateToContactUs">Contact Us</button>
    </h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- Each block can be a separate topic -->
      <div class="block">Cluster Architecture, Installation, and Configuration</div>
      <div class="block">Workloads and Scheduling</div>
      <div class="block">Services and Networking</div>
      <div class="block">Storage Management</div>
      <div class="block">Monitoring, Logging, and Troubleshooting</div>
      <div class="block">API Objects and Controllers</div>
      <div class="block">Security in Kubernetes</div>
      <div class="block">Kubernetes High Availability</div>
      <div class="block">Backup and Restore Strategies</div>
      <div class="block">Kubernetes Upgrades and Maintenance</div>
      <div class="block">Managing Kubernetes with kubectl</div>
      <div class="block">Automation and CI/CD Pipelines in Kubernetes</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    navigateToContactUs() {
      this.$router.push({ name: 'ContactUs' });
    },
  },
};
</script>
<style scoped>
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.page-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
}

.block {
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  padding: 20px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.block:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #2196f3, #1976d2); /* Blue gradient */
  color: #ffffff;
}
.contact-us-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us-button:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
</style>

