<template>
  <div class="main-content grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
    <!-- Azure Block -->
    <div class="frame-block" @click="navigateTo('AzurePage')">
      <img src="@/assets/azure.png" alt="Azure" class="block-image" />
      <h3 class="block-title">Azure</h3>
      <p class="block-content">Cloud Computing - Microsoft Azure Services</p>
    </div>
    <!-- MCSA Block -->
    <div class="frame-block" @click="navigateTo('MCSAPage')">
      <img src="@/assets/mcsa.png" alt="MCSA" class="block-image" />
      <h3 class="block-title">MCSA</h3>
      <p class="block-content">Microsoft Certified Solutions Associate</p>
    </div>
    <!-- VMware Block -->
    <div class="frame-block" @click="navigateTo('VMwarePage')">
      <img src="@/assets/vmware.png" alt="VMware" class="block-image" />
      <h3 class="block-title">VMware</h3>
      <p class="block-content">VMware Virtualization Technology</p>
    </div>
    <!-- Exchange Block -->
    <div class="frame-block" @click="navigateTo('ExchangePage')">
      <img src="@/assets/exchange.png" alt="Exchange" class="block-image" />
      <h3 class="block-title">M365-ExchangeOnline</h3>
      <p class="block-content">M365 - Microsoft Exchange Online</p>
    </div>
    <!-- Kubernetes Block -->
    <div class="frame-block" @click="navigateTo('KubernetesPage')">
      <img src="@/assets/kubernetes.png" alt="Kubernetes" class="block-image" />
      <h3 class="block-title">Kubernetes</h3>
      <p class="block-content">Kubernetes CKA</p>
    </div>
    <!-- IaC Block -->
    <div class="frame-block" @click="navigateTo('IaCPage')">
      <img src="@/assets/iac.png" alt="IaC" class="block-image" />
      <h3 class="block-title">IaC</h3>
      <p class="block-content">IaC PowerShell Terraform Git</p>
    </div>
    <!-- Contact Us Block -->
    <div class="frame-block" @click="navigateTo('ContactUs')">
      <img src="@/assets/contact.png" alt="Contact Us" class="block-image" />
      <h3 class="block-title">Contact Us</h3>
      <p class="block-content">For course details, Contact Us</p>
    </div>
    <!-- Web Stories -->
    <div class="frame-block" @click="navigateTo('WebStories')">
      <img src="@/assets/webStory.png" alt="Web Stories" class="block-image" />
      <h3 class="block-title">IT News</h3>
      <p class="block-content">IT infrastructure updates</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    navigateTo(pageName) {
      this.$router.push({ name: pageName });
    },
  },
};
</script>

<style scoped>
.main-content {
  display: grid;
  gap: 1.5rem;
}

.frame-block {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  text-align: center;
}

.frame-block:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.block-image {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
}

.block-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  position: relative;
  display: inline-block;
  animation: titleAnimation 5s infinite alternate;
}

@keyframes titleAnimation {
  0% {
    transform: scale(1);
    color: #1e3a8a;
  }
  50% {
    transform: scale(1.1);
    color: #3b82f6;
  }
  100% {
    transform: scale(1.2);
    color: #ffffff;
  }
}

.block-content {
  font-size: 1rem;
}
</style>

