<template>
  <div class="page-container">
    <h1 class="page-title">Terraform, Git/GitHub, PowerShell Topics, for details ,
    <button class="contact-us-button" @click="navigateToContactUs">Contact Us</button>
    </h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- Terraform Topics -->
      <div class="block">Introduction to Terraform</div>
      <div class="block">Terraform Providers and Resources</div>
      <div class="block">Managing Infrastructure as Code with Terraform</div>
      <div class="block">Terraform State and Data Management</div>
      <div class="block">Terraform Modules</div>
      <div class="block">Terraform Cloud and Enterprise</div>
      
      <!-- Git/GitHub Topics -->
      <div class="block">Introduction to Git</div>
      <div class="block">Working with Git Repositories</div>
      <div class="block">Branching, Merging, and Rebasing in Git</div>
      <div class="block">Collaborating with GitHub</div>
      <div class="block">GitHub Pull Requests and Code Reviews</div>
      <div class="block">CI/CD with GitHub Actions</div>
      
      <!-- PowerShell Topics -->
      <div class="block">Introduction to PowerShell</div>
      <div class="block">PowerShell Scripting Basics</div>
      <div class="block">Managing Windows Systems with PowerShell</div>
      <div class="block">Advanced Scripting Techniques</div>
      <div class="block">PowerShell for Azure and Cloud Management</div>
      <div class="block">Automating Tasks with PowerShell</div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    navigateToContactUs() {
      this.$router.push({ name: 'ContactUs' });
    },
  },
};
</script>
<style scoped>
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.page-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
}

.block {
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  padding: 20px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.block:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #6a1b9a, #8e24aa); /* Purple gradient */
  color: #ffffff;
}
.contact-us-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us-button:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
</style>

