<template>
  <div class="map-container">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15070.285268289059!2d73.07946964831957!3d19.213917212305287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7958baf37fa3f%3A0x20dabf871b22a470!2sNTMS%20Training%20Institute!5e0!3m2!1sen!2sin!4v1725448734199!5m2!1sen!2sin"
      width="600"
      height="450"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</template>

<style scoped>
.map-container {
  max-width: 100%;
  overflow: hidden;
  text-align: center;
  margin-top: 20px;
}

.map-container iframe {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 10px;
}
</style>

