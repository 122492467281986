<template>
  <div class="about-us-container">
    <h1 class="title">About Us</h1>
    <div class="content">
      <p>
        At NTMS Training Institute, our mission is to empower students to elevate their technical skills and prepare them for success in the fast-paced IT industry. Whether you’re aiming to pass certification exams like MCSE or Cisco or seeking to enhance your workplace productivity, our job-oriented training is designed to meet your needs.
      </p>
      <p>
        Our approach goes beyond just teaching technology. We focus on real-world solutions to help you overcome the challenges of difficult-to-understand technologies, tough job interviews, rigorous exams, and building self-confidence. We believe in making winners out of people, and we’re dedicated to supporting you every step of the way.
      </p>
      <p>
        Our reputation speaks for itself, with most of our students joining us through word-of-mouth recommendations—a testament to the quality of our service and the success of our alumni.
      </p>
      <p>
        Join us and take the next step in your professional journey. Explore our courses today and discover how NTMS can help you achieve your goals.
      </p>
    </div>

    <h2 class="team-title">Meet Our Team</h2>
    <div class="team-container">
      <div v-for="(member, index) in teamMembers" :key="index" class="card">
        <div class="card-inner">
          <!-- Front of the card -->
          <div class="card-front">
            <h3>{{ member.name }}</h3>
          </div>
          <!-- Back of the card -->
          <div class="card-back">
            <p>Some details about {{ member.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      teamMembers: [
        { name: "Sanjay Dubey" },
        { name: "Shashikant Patil" },
        { name: "Amit Sahu" },
        { name: "Dinesh Patil" },
        { name: "Ayushi Dubey" },
      ],
    };
  },
};
</script>

<style scoped>
.about-us-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #1e40af;
}

.content p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 16px;
  color: #333;
}

.content p:hover {
  color: #1e40af;
  transition: color 0.3s ease;
}

.about-us-container:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

/* Team Section */
.team-title {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 40px;
  color: #1e40af;
}

.team-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px; /* Adds space between cards */
}

.card {
  background-color: white; /* White background for the card */
  color: black; /* Black text for team member name */
  width: 180px;
  height: 180px;
  margin: 10px;
  perspective: 1000px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 4px solid transparent; /* Initial transparent border */
  background-clip: padding-box;
  position: relative;
}

.card:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: linear-gradient(90deg, white, yellow, white); /* White-yellow-white gradient */
  z-index: -1;
  border-radius: 12px;
}

.card:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-front {
  background-color: white; /* Ensure front is white */
  color: black; /* Black text on front */
}

.card-back {
  background-color: #1e40af; /* Back of the card */
  color: white;
  transform: rotateY(180deg);
}
</style>

