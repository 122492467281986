<template>
  <nav class="bg-gradient-to-r from-blue-500 to-blue-700 text-white p-4">
    <div class="container mx-auto flex justify-between items-center">
      <h1 class="text-2xl font-bold dynamic-gradient-text">
        NTMS Training Institute Portal
      </h1>
      <div class="flex justify-center w-full md:w-auto">
        <button @click="navigateHome" class="home-button">
          Home
        </button>
      </div>
      <button @click="toggleMenu" class="md:hidden block">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      <ul :class="['md:flex', isMenuOpen ? 'block' : 'hidden']" class="space-x-4">
        <li><router-link to="/AboutUs" class="hover:text-gray-300 transition duration-300">About Us</router-link></li>
        <li><router-link to="/ContactUs" class="hover:text-gray-300 transition duration-300">Contact Us</router-link></li>
        <li><router-link to="/UpcomingBatches" class="hover:text-gray-300 transition duration-300">Upcoming Batches</router-link></li>
        <li><router-link to="/TestimonialsPage" class="hover:text-gray-300 transition duration-300">Testimonials</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    navigateHome() {
      this.$router.push({ name: 'HomePage' });
    },
  },
};
</script>

<style scoped>
.home-button {
  background: linear-gradient(to right, white, green);
  color: #1e40af; /* Indigo text color */
  padding: 8px 16px;
  border-radius: 12px;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-button:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); /* More pronounced shadow on hover */
}

.dynamic-gradient-text {
  background: linear-gradient(to right, #ffffff, #22c55e, #ffffff); /* Blue-white gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradient-animation 5s ease infinite; /* Animation for dynamic gradient */
}

@keyframes gradient-animation {
  0% {
    background-position: left center;
  }
  50% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
</style>

