<template>
  <div class="page-container">
    <h1 class="page-title">For complete course details,
    <button class="contact-us-button" @click="navigateToContactUs">Contact Us</button>
    </h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- Topic Blocks -->
      <div class="topic-block" v-for="(topic, index) in topics" :key="index">
        <h2 class="block-title">{{ topic.title }}</h2>
        <p class="block-content">{{ topic.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topics: [
        { title: "Introduction to Azure", content: "IaaS , PaaS, SaaS, Architecture of Azure Cloud." },
        { title: "Azure Virtual Machines", content: "Dive into Azure Virtual Machines and understand the compute services Azure offers." },
        { title: "Azure Storage Solutions", content: "Discover various storage solutions provided by Azure and their use cases." },
        { title: "Azure Networking", content: "Azure Virtual Network, Subnets, L4 and L7 Load Balancer, Vnet Peering, VPN, Express Route..." },
        { title: "Azure Security", content: "Microsoft Defender for Cloud, Azure Firewall, Azure Sentinel..." },
        { title: "Azure Monitoring", content: "Explore monitoring and management tools in Azure for optimizing performance." },
        { title: "Azure DevOps", content: "ARM Templates, Bicep, Terraform, Git/GitHub Actions, Azure Pipeline." },
        { title: "Azure Migration", content: "Migrate Hyperv VM Vmware VM to Azure Cloud.Migrate VM apps to PaaS based services" },
        { title: "Azure Active Directory or Microsoft Entra ID", content: "Concepts, Integration with On-premises Active Direcory." },
        { title: "Azure Exams:", content: "This course covers major topics from Exam Az900, Az104, Az305, Az500, SC300, Az700, Az140, Az400." }
      ],
    };
  },
  methods: {
    navigateToContactUs() {
      this.$router.push({ name: 'ContactUs' });
    },
  },
};
</script>


<style scoped>
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.page-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #1e3a8a; /* Indigo color */
}

.grid {
  display: grid;
  gap: 1.5rem; /* Spacing between blocks */
}

.topic-block {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  border: 2px solid #e0e0e0;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.topic-block:hover {
  transform: translateY(-10px); /* Lift effect */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Deeper shadow */
  background: linear-gradient(135deg, #ff7e5f, #feb47b); /* Gradient background */
  color: white; /* White text color */
}

.block-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.block-content {
  font-size: 1rem;
  color: #4a4a4a; /* Grayish text color */
}

.topic-block:hover .block-title,
.topic-block:hover .block-content {
  color: white; /* Text color changes to white on hover */
}

.contact-us-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us-button:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
</style>

