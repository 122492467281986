<template>
  <footer class="bg-blue-600 text-white p-4 text-center">
    <p>&copy; 2024 Education Portal. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
/* Scoped styles for Footer component */
</style>

