<template>
  <div class="page-container">
    <h1 class="page-title">MCSA, For complete course details,
    <button class="contact-us-button" @click="navigateToContactUs">Contact Us</button> 
    </h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      <!-- Each block is a separate topic with eye-catching design -->
      <div class="block">
        <h3 class="block-title">Installing and Configuring Windows Server</h3>
        <p class="block-content">Learn how to install and configure Windows Server environments.</p>
      </div>
      <div class="block">
        <h3 class="block-title">Administering Windows Server</h3>
        <p class="block-content">Understand the management and administration of Windows Server.</p>
      </div>
      <div class="block">
        <h3 class="block-title">Configuring Advanced Windows Server Services</h3>
        <p class="block-content">Master advanced services and features in Windows Server.</p>
      </div>
      <div class="block">
        <h3 class="block-title">Networking with Windows Server</h3>
        <p class="block-content">Dive into networking concepts and configurations in Windows Server.</p>
      </div>
      <div class="block">
        <h3 class="block-title">Identity with Windows Server</h3>
        <p class="block-content">Learn about identity management and Active Directory.</p>
      </div>
      <div class="block">
        <h3 class="block-title">Securing Windows Server</h3>
        <p class="block-content">Explore security best practices for Windows Server.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    navigateToContactUs() {
      this.$router.push({ name: 'ContactUs' });
    },
  },
};
</script>

<style scoped>
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.page-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #1e40af; /* Indigo text color */
}

.grid {
  display: grid;
  gap: 20px;
}

.block {
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.block-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #3b82f6; /* Blue text color */
}

.block-content {
  font-size: 1rem;
  color: #4b5563; /* Cool gray text color */
}

.block::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1e3a8a, #3b82f6);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.block:hover::before {
  opacity: 0.1;
}

.block:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.block:hover .block-title {
  color: #1e40af; /* Darker blue on hover */
}

.block:hover .block-content {
  color: #1e3a8a; /* Indigo text color on hover */
}
.contact-us-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us-button:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
</style>

