<template>
  <div>
    <AppHeader />
    <div class="main-content-container">
      <div class="center-section">
      </div>
    </div>
    <router-view /> <!-- This will display the correct page component -->
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

