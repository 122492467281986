import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/MainContent.vue';
import AzurePage from '@/views/AzurePage.vue'; // Ensure paths are correct
import MCSAPage from '@/views/MCSAPage.vue';
import ExchangePage from '@/views/ExchangePage.vue';
import IaCPage from '@/views/IaCPage.vue';
import KubernetesPage from '@/views/KubernetesPage.vue';
import VMwarePage from '@/views/VMwarePage.vue';
import AboutUs from '@/views/AboutUs.vue';
import UpcomingBatches from '@/views/UpcomingBatches.vue';
import TestimonialsPage from '@/views/TestimonialsPage.vue';
import ContactUs from '@/components/ContactForm.vue';
import WebStories from '@/components/WebStory.vue';
const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },

  {
    path: '/AzurePage',
    name: 'AzurePage',
    component: AzurePage,
  },
  {
    path: '/MCSAPage',
    name: 'MCSAPage',
    component: MCSAPage,
  },
  {
    path: '/VmwarePage',
    name: 'VMwarePage',
    component: VMwarePage,
  },
  {
    path: '/ExchangePage',
    name: 'ExchangePage',
    component: ExchangePage,
  },
   {
    path: '/IaCPage',
    name: 'IaCPage',
    component: IaCPage,
  },
  {
    path: '/KubernetesPage',
    name: 'KubernetesPage',
    component: KubernetesPage,
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/TestimonialsPage',
    name: 'Testimonials',
    component: TestimonialsPage,
  },
  {
    path: '/UpcomingBatches',
    name: 'UpcomingBatches',
    component: UpcomingBatches,
  },
  {
    path: '/WebStories',
    name: 'WebStories',
    component: WebStories,
  } 
  // Add more routes as necessary
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

